@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Black.eot");
  src: url("../../media/fonts/SofiaPro-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Black.woff") format("woff"), url("../../media/fonts/SofiaPro-Black.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot");
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-BlackItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-BlackItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Black.eot");
  src: url("../../media/fonts/SofiaPro-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Black.woff") format("woff"), url("../../media/fonts/SofiaPro-Black.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Black.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot");
  src: url("../../media/fonts/SofiaPro-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-BlackItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-BlackItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-BlackItalic.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Bold.eot");
  src: url("../../media/fonts/SofiaPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Bold.woff") format("woff"), url("../../media/fonts/SofiaPro-Bold.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-BoldItalic.eot");
  src: url("../../media/fonts/SofiaPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-BoldItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-BoldItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-SemiBold.eot");
  src: url("../../media/fonts/SofiaPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-SemiBold.woff") format("woff"), url("../../media/fonts/SofiaPro-SemiBold.ttf") format("truetype"), url("../../media/fonts/SofiaPro-SemiBold.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-SemiBoldItalic.eot");
  src: url("../../media/fonts/SofiaPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-SemiBoldItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-SemiBoldItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-SemiBoldItalic.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Medium.eot");
  src: url("../../media/fonts/SofiaPro-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Medium.woff") format("woff"), url("../../media/fonts/SofiaPro-Medium.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-MediumItalic.eot");
  src: url("../../media/fonts/SofiaPro-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-MediumItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-MediumItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Regular.eot");
  src: url("../../media/fonts/SofiaPro-Regular.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Regular.woff") format("woff"), url("../../media/fonts/SofiaPro-Regular.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Regular.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Italic.eot");
  src: url("../../media/fonts/SofiaPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Italic.woff") format("woff"), url("../../media/fonts/SofiaPro-Italic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Italic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-Light.eot");
  src: url("../../media/fonts/SofiaPro-Light.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-Light.woff") format("woff"), url("../../media/fonts/SofiaPro-Light.ttf") format("truetype"), url("../../media/fonts/SofiaPro-Light.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../media/fonts/SofiaPro-LightItalic.eot");
  src: url("../../media/fonts/SofiaPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/SofiaPro-LightItalic.woff") format("woff"), url("../../media/fonts/SofiaPro-LightItalic.ttf") format("truetype"), url("../../media/fonts/SofiaPro-LightItalic.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Black.eot");
  src: url("../../media/fonts/Graphik-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Black.woff") format("woff"), url("../../media/fonts/Graphik-Black.ttf") format("truetype"), url("../../media/fonts/Graphik-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-BlackItalic.eot");
  src: url("../../media/fonts/Graphik-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-BlackItalic.woff") format("woff"), url("../../media/fonts/Graphik-BlackItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Black.eot");
  src: url("../../media/fonts/Graphik-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Black.woff") format("woff"), url("../../media/fonts/Graphik-Black.ttf") format("truetype"), url("../../media/fonts/Graphik-Black.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-BlackItalic.eot");
  src: url("../../media/fonts/Graphik-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-BlackItalic.woff") format("woff"), url("../../media/fonts/Graphik-BlackItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-BlackItalic.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Bold.eot");
  src: url("../../media/fonts/Graphik-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Bold.woff") format("woff"), url("../../media/fonts/Graphik-Bold.ttf") format("truetype"), url("../../media/fonts/Graphik-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-BoldItalic.eot");
  src: url("../../media/fonts/Graphik-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-BoldItalic.woff") format("woff"), url("../../media/fonts/Graphik-BoldItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-SemiBold.eot");
  src: url("../../media/fonts/Graphik-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-SemiBold.woff") format("woff"), url("../../media/fonts/Graphik-SemiBold.ttf") format("truetype"), url("../../media/fonts/Graphik-SemiBold.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-SemiBoldItalic.eot");
  src: url("../../media/fonts/Graphik-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-SemiBoldItalic.woff") format("woff"), url("../../media/fonts/Graphik-SemiBoldItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-SemiBoldItalic.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Medium.eot");
  src: url("../../media/fonts/Graphik-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Medium.woff") format("woff"), url("../../media/fonts/Graphik-Medium.ttf") format("truetype"), url("../../media/fonts/Graphik-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-MediumItalic.eot");
  src: url("../../media/fonts/Graphik-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-MediumItalic.woff") format("woff"), url("../../media/fonts/Graphik-MediumItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Regular.eot");
  src: url("../../media/fonts/Graphik-Regular.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Regular.woff") format("woff"), url("../../media/fonts/Graphik-Regular.ttf") format("truetype"), url("../../media/fonts/Graphik-Regular.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-RegularItalic.eot");
  src: url("../../media/fonts/Graphik-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-RegularItalic.woff") format("woff"), url("../../media/fonts/Graphik-RegularItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-RegularItalic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-Light.eot");
  src: url("../../media/fonts/Graphik-Light.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-Light.woff") format("woff"), url("../../media/fonts/Graphik-Light.ttf") format("truetype"), url("../../media/fonts/Graphik-Light.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("../../media/fonts/Graphik-LightItalic.eot");
  src: url("../../media/fonts/Graphik-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Graphik-LightItalic.woff") format("woff"), url("../../media/fonts/Graphik-LightItalic.ttf") format("truetype"), url("../../media/fonts/Graphik-LightItalic.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Black.eot");
  src: url("../../media/fonts/Inter-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Black.woff") format("woff"), url("../../media/fonts/Inter-Black.ttf") format("truetype"), url("../../media/fonts/Inter-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-BlackItalic.eot");
  src: url("../../media/fonts/Inter-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-BlackItalic.woff") format("woff"), url("../../media/fonts/Inter-BlackItalic.ttf") format("truetype"), url("../../media/fonts/Inter-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-ExtraBold.eot");
  src: url("../../media/fonts/Inter-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-ExtraBold.woff") format("woff"), url("../../media/fonts/Inter-ExtraBold.ttf") format("truetype"), url("../../media/fonts/Inter-ExtraBold.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-ExtraBoldItalic.eot");
  src: url("../../media/fonts/Inter-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-ExtraBoldItalic.woff") format("woff"), url("../../media/fonts/Inter-ExtraBoldItalic.ttf") format("truetype"), url("../../media/fonts/Inter-ExtraBoldItalic.svg?#webfont") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Bold.eot");
  src: url("../../media/fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Bold.woff") format("woff"), url("../../media/fonts/Inter-Bold.ttf") format("truetype"), url("../../media/fonts/Inter-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-BoldItalic.eot");
  src: url("../../media/fonts/Inter-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-BoldItalic.woff") format("woff"), url("../../media/fonts/Inter-BoldItalic.ttf") format("truetype"), url("../../media/fonts/Inter-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-SemiBold.eot");
  src: url("../../media/fonts/Inter-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-SemiBold.woff") format("woff"), url("../../media/fonts/Inter-SemiBold.ttf") format("truetype"), url("../../media/fonts/Inter-SemiBold.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-SemiBoldItalic.eot");
  src: url("../../media/fonts/Inter-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-SemiBoldItalic.woff") format("woff"), url("../../media/fonts/Inter-SemiBoldItalic.ttf") format("truetype"), url("../../media/fonts/Inter-SemiBoldItalic.svg?#webfont") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Medium.eot");
  src: url("../../media/fonts/Inter-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Medium.woff") format("woff"), url("../../media/fonts/Inter-Medium.ttf") format("truetype"), url("../../media/fonts/Inter-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-MediumItalic.eot");
  src: url("../../media/fonts/Inter-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-MediumItalic.woff") format("woff"), url("../../media/fonts/Inter-MediumItalic.ttf") format("truetype"), url("../../media/fonts/Inter-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Regular.eot");
  src: url("../../media/fonts/Inter-Regular.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Regular.woff") format("woff"), url("../../media/fonts/Inter-Regular.ttf") format("truetype"), url("../../media/fonts/Inter-Regular.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-RegularItalic.eot");
  src: url("../../media/fonts/Inter-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-RegularItalic.woff") format("woff"), url("../../media/fonts/Inter-RegularItalic.ttf") format("truetype"), url("../../media/fonts/Inter-RegularItalic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-Light.eot");
  src: url("../../media/fonts/Inter-Light.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-Light.woff") format("woff"), url("../../media/fonts/Inter-Light.ttf") format("truetype"), url("../../media/fonts/Inter-Light.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../../media/fonts/Inter-LightItalic.eot");
  src: url("../../media/fonts/Inter-LightItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/Inter-LightItalic.woff") format("woff"), url("../../media/fonts/Inter-LightItalic.ttf") format("truetype"), url("../../media/fonts/Inter-LightItalic.svg?#webfont") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Black.eot");
  src: url("../../media/fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Black.woff") format("woff"), url("../../media/fonts/CircularStd-Black.ttf") format("truetype"), url("../../media/fonts/CircularStd-Black.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-BlackItalic.eot");
  src: url("../../media/fonts/CircularStd-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-BlackItalic.woff") format("woff"), url("../../media/fonts/CircularStd-BlackItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-BlackItalic.svg?#webfont") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Bold.eot");
  src: url("../../media/fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Bold.woff") format("woff"), url("../../media/fonts/CircularStd-Bold.ttf") format("truetype"), url("../../media/fonts/CircularStd-Bold.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-BoldItalic.eot");
  src: url("../../media/fonts/CircularStd-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-BoldItalic.woff") format("woff"), url("../../media/fonts/CircularStd-BoldItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-BoldItalic.svg?#webfont") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Medium.eot");
  src: url("../../media/fonts/CircularStd-Medium.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Medium.woff") format("woff"), url("../../media/fonts/CircularStd-Medium.ttf") format("truetype"), url("../../media/fonts/CircularStd-Medium.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-MediumItalic.eot");
  src: url("../../media/fonts/CircularStd-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-MediumItalic.woff") format("woff"), url("../../media/fonts/CircularStd-MediumItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-MediumItalic.svg?#webfont") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-Book.eot");
  src: url("../../media/fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-Book.woff") format("woff"), url("../../media/fonts/CircularStd-Book.ttf") format("truetype"), url("../../media/fonts/CircularStd-Book.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../../media/fonts/CircularStd-BookItalic.eot");
  src: url("../../media/fonts/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"), url("../../media/fonts/CircularStd-BookItalic.woff") format("woff"), url("../../media/fonts/CircularStd-BookItalic.ttf") format("truetype"), url("../../media/fonts/CircularStd-BookItalic.svg?#webfont") format("svg");
  font-weight: 400;
  font-style: italic;
}
/*!
 * Bootstrap Reboot v5.3.0 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #1E68F6;
  --bs-indigo: #6610f2;
  --bs-purple: #BD47FB;
  --bs-pink: #d63384;
  --bs-red: #FA2256;
  --bs-orange: #fd7e14;
  --bs-yellow: #F27F16;
  --bs-green: #11CABE;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #FFFFFF;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #246CF9;
  --bs-secondary: #141518;
  --bs-success: #11CABE;
  --bs-info: #1E68F6;
  --bs-warning: #F27F16;
  --bs-danger: #FA2256;
  --bs-light: #FFFFFF;
  --bs-dark: #141518;
  --bs-royal-blue: #246CF9;
  --bs-baby-blue-light: #EDFDFF;
  --bs-ultramarine-blue: #706DF8;
  --bs-ultramine-blue-pale: #E9E9FF;
  --bs-ultramarine-blue-light: #F4F3FF;
  --bs-white: #FFFFFF;
  --bs-grey-20: #E5E6ED;
  --bs-grey-40: #A5ADCF;
  --bs-grey-60: #5D6588;
  --bs-grey-80: #34384C;
  --bs-grey-100: #1E1F25;
  --bs-darker-grey: #141518;
  --bs-dark-transparent: rgba(47, 50, 65, 0.5);
  --bs-black: #000000;
  --bs-blue: #1E68F6;
  --bs-blue-pale: #BEE4FF;
  --bs-yellow: #F27F16;
  --bs-yellow-pale: #FFE4B1;
  --bs-green: #11CABE;
  --bs-green-pale: #CCF0E6;
  --bs-red: #FA2256;
  --bs-red-pale: #FFD1CB;
  --bs-primary-rgb: 36, 108, 249;
  --bs-secondary-rgb: 20, 21, 24;
  --bs-success-rgb: 17, 202, 190;
  --bs-info-rgb: 30, 104, 246;
  --bs-warning-rgb: 242, 127, 22;
  --bs-danger-rgb: 250, 34, 86;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 20, 21, 24;
  --bs-royal-blue-rgb: 36, 108, 249;
  --bs-baby-blue-light-rgb: 237, 253, 255;
  --bs-ultramarine-blue-rgb: 112, 109, 248;
  --bs-ultramine-blue-pale-rgb: 233, 233, 255;
  --bs-ultramarine-blue-light-rgb: 244, 243, 255;
  --bs-white-rgb: 255, 255, 255;
  --bs-grey-20-rgb: 229, 230, 237;
  --bs-grey-40-rgb: 165, 173, 207;
  --bs-grey-60-rgb: 93, 101, 136;
  --bs-grey-80-rgb: 52, 56, 76;
  --bs-grey-100-rgb: 30, 31, 37;
  --bs-darker-grey-rgb: 20, 21, 24;
  --bs-dark-transparent-rgb: 47, 50, 65;
  --bs-black-rgb: 0, 0, 0;
  --bs-blue-rgb: 30, 104, 246;
  --bs-blue-pale-rgb: 190, 228, 255;
  --bs-yellow-rgb: 242, 127, 22;
  --bs-yellow-pale-rgb: 255, 228, 177;
  --bs-green-rgb: 17, 202, 190;
  --bs-green-pale-rgb: 204, 240, 230;
  --bs-red-rgb: 250, 34, 86;
  --bs-red-pale-rgb: 255, 209, 203;
  --bs-primary-text-emphasis: #0e2b64;
  --bs-secondary-text-emphasis: #08080a;
  --bs-success-text-emphasis: #07514c;
  --bs-info-text-emphasis: #0c2a62;
  --bs-warning-text-emphasis: #613309;
  --bs-danger-text-emphasis: #640e22;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d3e2fe;
  --bs-secondary-bg-subtle: #d0d0d1;
  --bs-success-bg-subtle: #cff4f2;
  --bs-info-bg-subtle: #d2e1fd;
  --bs-warning-bg-subtle: #fce5d0;
  --bs-danger-bg-subtle: #fed3dd;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a7c4fd;
  --bs-secondary-border-subtle: #a1a1a3;
  --bs-success-border-subtle: #a0eae5;
  --bs-info-border-subtle: #a5c3fb;
  --bs-warning-border-subtle: #facca2;
  --bs-danger-border-subtle: #fda7bb;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Sofia Pro", sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.88;
  --bs-body-color: #FFFFFF;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-body-bg: #141518;
  --bs-body-bg-rgb: 20, 21, 24;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(255, 255, 255, 0.75);
  --bs-secondary-color-rgb: 255, 255, 255;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(255, 255, 255, 0.5);
  --bs-tertiary-color-rgb: 255, 255, 255;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #FFFFFF;
  --bs-link-color: #246CF9;
  --bs-link-color-rgb: 36, 108, 249;
  --bs-link-decoration: none;
  --bs-link-hover-color: #1d56c7;
  --bs-link-hover-color-rgb: 29, 86, 199;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fce5d0;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E5E6ED;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 10px;
  --bs-border-radius-sm: 5px;
  --bs-border-radius-lg: 15px;
  --bs-border-radius-xl: 20px;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0px 12px 36px rgba(159, 165, 174, 0.08);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(36, 108, 249, 0.25);
  --bs-form-valid-color: #11CABE;
  --bs-form-valid-border-color: #11CABE;
  --bs-form-invalid-color: #FA2256;
  --bs-form-invalid-border-color: #FA2256;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #FFFFFF;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #7ca7fb;
  --bs-secondary-text-emphasis: #727374;
  --bs-success-text-emphasis: #70dfd8;
  --bs-info-text-emphasis: #78a4fa;
  --bs-warning-text-emphasis: #f7b273;
  --bs-danger-text-emphasis: #fc7a9a;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #071632;
  --bs-secondary-bg-subtle: #040405;
  --bs-success-bg-subtle: #032826;
  --bs-info-bg-subtle: #061531;
  --bs-warning-bg-subtle: #301904;
  --bs-danger-bg-subtle: #320711;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #164195;
  --bs-secondary-border-subtle: #0c0d0e;
  --bs-success-border-subtle: #0a7972;
  --bs-info-border-subtle: #123e94;
  --bs-warning-border-subtle: #914c0d;
  --bs-danger-border-subtle: #961434;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #7ca7fb;
  --bs-link-hover-color: #96b9fc;
  --bs-link-color-rgb: 124, 167, 251;
  --bs-link-hover-color-rgb: 150, 185, 252;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #70dfd8;
  --bs-form-valid-border-color: #70dfd8;
  --bs-form-invalid-color: #fc7a9a;
  --bs-form-invalid-border-color: #fc7a9a;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.50625rem + 3.075vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 3.8125rem;
  }
}

h2 {
  font-size: calc(1.43125rem + 2.175vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 3.0625rem;
  }
}

h3 {
  font-size: calc(1.36875rem + 1.425vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 2.4375rem;
  }
}

h4 {
  font-size: calc(1.31875rem + 0.825vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.9375rem;
  }
}

h5 {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h5 {
    font-size: 1.5625rem;
  }
}

h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 5px;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: -0.01em;
}

* {
  font-smooth: always;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  scroll-behavior: smooth !important;
}

a {
  font-weight: 700;
}

h1 {
  font-size: 3.8125rem;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  h1 {
    font-size: 2.125rem;
    line-height: 1.1;
  }
}

h2 {
  font-size: 3.0625rem;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  h2 {
    font-size: 1.75rem;
    line-height: 1.1;
  }
}

h3 {
  font-size: 2.4375rem;
  line-height: 1.1;
}
@media (max-width: 575.98px) {
  h3 {
    font-size: 1.375rem;
    line-height: 1.1;
  }
}

h4 {
  font-size: 1.9375rem;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  h4 {
    font-size: 1.125rem;
    line-height: 1.2;
  }
}

h5 {
  font-size: 1.5625rem;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  h5 {
    font-size: 1rem;
    line-height: 1.2;
  }
}

h6 {
  font-size: 1.25rem;
  line-height: 1.2;
}
@media (max-width: 575.98px) {
  h6 {
    font-size: 0.875rem;
    line-height: 1.2;
  }
}

@media (max-width: 767.98px) {
  .container {
    padding: 0 1.5rem;
  }
}
@media (max-width: 374.98px) {
  .container {
    padding: 0 1rem;
  }
}
.container.container--dashboard {
  max-width: 90rem;
  padding: 0 2.75rem;
}
@media (max-width: 1199.98px) {
  .container.container--dashboard {
    padding: 0 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .container.container--dashboard {
    padding: 0 2rem;
  }
}
@media (max-width: 767.98px) {
  .container.container--dashboard {
    padding: 0 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .container.container--dashboard {
    padding: 0 1rem;
  }
}
.container.container--dashboard-nav {
  max-width: 100% !important;
  padding: 0 2.75rem;
}
@media (max-width: 1199.98px) {
  .container.container--dashboard-nav {
    padding: 0 2.5rem;
  }
}
@media (max-width: 991.98px) {
  .container.container--dashboard-nav {
    padding: 0 2rem;
  }
}
@media (max-width: 767.98px) {
  .container.container--dashboard-nav {
    padding: 0 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .container.container--dashboard-nav {
    padding: 0 1rem;
  }
}

.fd-xl {
  font-size: 3.5rem;
  line-height: 1.2;
  margin: 0;
  font-weight: 500;
  font-family: "Graphik", sans-serif;
}
.fd-xl--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-xl {
    font-size: 2.4375rem;
  }
}

.fd-lg {
  font-size: 2.4375rem;
  line-height: 1.2;
  margin: 0;
  font-weight: 500;
  font-family: "Graphik", sans-serif;
}
.fd-lg--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-lg {
    font-size: 1.75rem;
  }
}

.fd-md {
  font-size: 1.5625rem;
  line-height: 1.1;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fd-md--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-md {
    font-size: 1.25rem;
  }
}

.fd-sm {
  font-size: 1.25rem;
  line-height: 1.1;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fd-sm--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fd-sm {
    font-size: 1.125rem;
  }
}

.fb-lg {
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-lg--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fb-lg {
    font-size: 1rem;
  }
}

.fb-regular {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-regular--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fb-regular {
    font-size: 0.875rem;
  }
}

.fb-sm {
  font-size: 0.875rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-sm--bold {
  font-weight: 600;
}
@media (max-width: 575.98px) {
  .fb-sm {
    font-size: 0.75rem;
  }
}

.fb-xs {
  font-size: 0.75rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;
  font-family: "Graphik", sans-serif;
}
.fb-xs--bold {
  font-weight: 600;
}

.text-bullish {
  color: #11CABE;
}

.text-bearish {
  color: #FA2256;
}

.text-green-light {
  color: #30E0A1;
}

@-webkit-keyframes ScaleBig {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ScaleBig {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes ScaleBigHideFirst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.25;
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.5;
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes ScaleBigHideFirst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.25;
  }
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.5;
  }
  75% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    opacity: 0.75;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.ScaleBig {
  animation: ScaleBig 0.8s linear;
}

.ScaleBigHideFirst {
  animation: ScaleBigHideFirst 0.8s linear;
}

body {
  background-image: url(../../media/images/sign-in/signin-page-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.sign-in {
  position: relative;
  min-height: 64rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 767.98px) {
  .sign-in {
    min-height: unset;
    height: 100%;
  }
}
.sign-in .sign-in-card {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;
  max-width: 33.9375rem;
  height: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: #22242C;
  border-radius: 0.75rem;
}
@media (max-width: 767.98px) {
  .sign-in .sign-in-card {
    max-width: 100%;
    margin: 0;
    border-radius: 0;
    padding: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .sign-in .sign-in-card {
    padding: 1rem;
  }
}
.sign-in .sign-in-card .sign-in__heading {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.sign-in .sign-in-card .sign-in__heading img {
  width: auto;
  height: auto;
  max-width: 8.75rem;
  max-height: 3.125rem;
}
.sign-in .sign-in-card .sign-in__title {
  color: #A5ADCF;
}
.sign-in .sign-in-card .btn-google-signin {
  position: relative;
  width: 100%;
  padding: 1rem;
  border: 1px solid #FFFFFF;
  border-radius: 6.25rem;
  color: #FFFFFF;
  background: transparent;
}
.sign-in .sign-in-card .btn-google-signin:hover {
  background: #1E1F25;
}
.sign-in .sign-in-card .sign-in__break-or {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.sign-in .sign-in-card .sign-in__break-or .line {
  display: block;
  height: 0.0625rem;
  width: 100%;
  background: #34384C;
}
.sign-in .sign-in-card .sign-in__break-or p {
  color: #A5ADCF;
}
.sign-in .sign-in-card .fb-sm {
  font-size: 0.875rem !important;
}
.sign-in .sign-in-card .sign-in__forms {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.sign-in .sign-in-card .sign-in__forms .form-control {
  font-size: 1.125rem;
  padding: 1rem 1.75rem;
}
.sign-in .sign-in-card .sign-in__forms .form-control::placeholder {
  font-weight: 400;
  color: #A5ADCF;
}
.sign-in .sign-in-card .sign-in__forms .forms-group {
  position: relative;
}
.sign-in .sign-in-card .sign-in__forms .forms-group .form-group__password-toggle {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 1.75rem;
  height: 1.5rem;
  width: 1.5rem;
  background-image: url(../../media/images/icons/eye.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  z-index: 2;
  cursor: pointer;
}
.sign-in .sign-in-card .sign-in__forms .remember-forgot-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
@media (max-width: 374.98px) {
  .sign-in .sign-in-card .sign-in__forms .remember-forgot-password {
    flex-direction: column;
  }
}
.sign-in .sign-in-card .sign-in__forms .remember-forgot-password .forgot-password {
  position: relative;
  top: 0.125rem;
  color: #E5E6ED;
  text-decoration: underline;
  font-weight: 500;
  transition: all linear 0.2s;
}
.sign-in .sign-in-card .sign-in__forms .remember-forgot-password .forgot-password:hover {
  color: #246CF9;
}
.sign-in .sign-in-card .sign-in__forms .btn-submit {
  margin-top: 0.5rem;
  padding: 1rem !important;
  font-size: 1rem !important;
  width: 100%;
}
@media (max-width: 767.98px) {
  .sign-in .sign-in-card .sign-in__forms .btn-submit {
    margin-top: 3.125rem;
  }
}
.sign-in .sign-in-card .sign-up-info {
  display: inline;
  text-align: center;
  margin: 0 auto;
  color: #FFFFFF;
}
.sign-in .sign-in-card .sign-up-info .sign-up-link {
  color: #FFFFFF;
  text-decoration: underline;
  font-weight: 500;
  transition: all linear 0.2s;
}
.sign-in .sign-in-card .sign-up-info .sign-up-link:hover {
  color: #246CF9;
}